<template>
  <div class="container-fluid">
    <PickupPointNavTab />
    <div class="p-lg-2">
      <b-form ref="form" class="pt-5" @submit.prevent>
        <h2 class="subtitle roboto bold">{{pickupPointId ? 'Edit' : 'Add'}} Your Pick-up Point Address</h2>
        <hr />
        <!-- Name -->
        <div class="row pb-2">
          <div class="col-md-2 pt-2">Name <b style="color: red;">*</b></div>
          <div class="col-md-7">
            <InputText
              type="text"
              v-model="$v.formData.name.$model"
              placeholder="Your Pick-Up Point Name (Ex: Whizliz Office)"
            />
          </div>
        </div>
        <!-- Address -->
        <div class="row pb-2">
          <div class="col-md-2 pt-2">Address <b style="color: red;">*</b></div>
          <div class="col-md-7">
            <InputText
              type="textarea"
              v-model="$v.formData.address.$model"
              placeholder="Enter your pick-up point address (i.e: Jl. Sunda no. 55, Bandung Kidul)"
            />
          </div>
        </div>
        <!-- Phone -->
        <div class="row pb-2">
          <div class="col-md-2 pt-2">Phone <b style="color: red;">*</b></div>
          <div class="col-md-7">
            <InputText
              type="text"
              v-model="$v.formData.contact.$model"
              placeholder="+62 xxx xxxx xxxx"
              maxlength="14"
            />
          </div>
        </div>
        <!-- Duration -->
        <div class="row pb-2">
          <div class="col-md-2 pt-2">Duration <b style="color: red;">*</b></div>
          <div class="col-md-2">
            <InputText
              type="number"
              v-model="$v.formData.duration.$model"
              placeholder="0"
              info="ETA of products to arrive at the pick-up point"
            />
          </div>
        </div>
        <!-- Operating Days -->
        <div class="row pb-2">
          <div class="col-md-2 pt-2">Operating Days <b style="color: red;">*</b></div>
          <div class="col-md-7 pt-2">
            <div v-for="key in Object.keys(formData.operating_day)" :key="key" class="d-inline text-nowrap">
              <input :id="`checkbox-${key}`" type="checkbox" v-model="formData.operating_day[key]" />
              <label :for="`checkbox-${key}`" class="pl-1 pr-2">{{key}}</label>
            </div>
          </div>
        </div>
        <!-- Operating Time -->
        <div class="row pb-2">
          <div class="col-md-2 pt-2">Operating Time <b style="color: red;">*</b></div>
          <div class="col-md-3 d-flex align-items-center">
            <InputText
              type="time"
              v-model="$v.formData.operating_start_time.$model"
            />
            <div class="p-2">to</div>
            <InputText
              type="time"
              v-model="$v.formData.operating_end_time.$model"
            />
          </div>
        </div>
        <!-- Latitude -->
        <div class="row pb-2">
          <div class="col-md-2 pt-2">Latitude <b style="color: red;">*</b></div>
          <div class="col-md-7">
            <input
              type="number"
              class="form-control"
              v-model="$v.formData.latitude.$model"
              placeholder="Latitude Coordinate"
              disabled
            />
          </div>
        </div>
        <!-- Longitude -->
        <div class="row pb-2">
          <div class="col-md-2 pt-2">Longitude <b style="color: red;">*</b></div>
          <div class="col-md-7">
            <input
              type="number"
              class="form-control"
              v-model="$v.formData.longitude.$model"
              placeholder="Longitude Coordinate"
              disabled
            />
          </div>
        </div>
        <!-- GMaps -->
        <div class="row pb-2">
          <div class="col">
            <div class="map-container">
              <input type="text" id="map-search" class="map-search" placeholder="Enter a location" />
              <div id="map" class="map"></div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <button type="button" class="btn btn-info ml-2" @click="submitData">Save</button>
          <router-link to="/list-pickup" class="btn btn-secondary ml-2">Cancel</router-link>
        </div>
      </b-form>
    </div>
  </div>
</template>
<script>
import { toaster } from "@/_helpers";
import PickupPointNavTab from "@/components/pickupPoint/PickupPointNavTab";
import InputText from '@/components/formCustom/InputText';

import { required, numeric, decimal, minValue } from "vuelidate/lib/validators";
import { Loader } from "@googlemaps/js-api-loader";

export default {
  name: "SavePickupPoint",
  components: {
    PickupPointNavTab,
    InputText,
  },
  data() {
    return {
      formData: {
        name: null,
        address: null,
        contact: null,
        duration: null,
        operating_day: {
          Senin: true,
          Selasa: true,
          Rabu: true,
          Kamis: true,
          Jumat: true,
          Sabtu: false,
          Minggu: false,
        },
        operating_start_time: "08:00",
        operating_end_time: "17:00",
        merchant_id: this.$store.getters.getMerchantData.merchant_id,
        latitude: null,
        longitude: null,
      }
    }
  },
  validations: {
    formData: {
      name: {required},
      address: {required},
      contact: {required},
      duration: {numeric, minValue: minValue(1)},
      operating_start_time: {required},
      operating_end_time: {required},
      latitude: {required, decimal},
      longitude: {required, decimal}
    }
  },
  computed: {
    pickupPointId() {
      return this.$route.params.id
    }
  },
  mounted() {
    if (this.pickupPointId) this.getPickupPointDetail(this.pickupPointId)
    else this.loadMap()
  },
  methods: {
    async getPickupPointDetail(id) {
      try {
        const res = await this.$api.pickuppoint.getPickupPointDetail(id)
        if (res.status === 200) {
          const pickup = res.data.data
          this.formData.name = pickup.name
          this.formData.address = pickup.address
          this.formData.contact = pickup.contact
          this.formData.duration = pickup.duration
          this.formData.operating_day = {
            Senin: pickup.operating_day.indexOf('Senin') != -1,
            Selasa: pickup.operating_day.indexOf('Selasa') != -1,
            Rabu: pickup.operating_day.indexOf('Rabu') != -1,
            Kamis: pickup.operating_day.indexOf('Kamis') != -1,
            Jumat: pickup.operating_day.indexOf('Jumat') != -1,
            Sabtu: pickup.operating_day.indexOf('Sabtu') != -1,
            Minggu: pickup.operating_day.indexOf('Minggu') != -1,
          }
          this.formData.operating_start_time = pickup.operating_start_time
          this.formData.operating_end_time = pickup.operating_end_time
          this.formData.merchant_id = pickup.merchant_id
          this.formData.latitude = pickup.latitude
          this.formData.longitude = pickup.longitude
          this.loadMap()
        } else {
          toaster.make(res.data.message, 'danger')
          this.$router.push('/list-pickup')
        }
      } catch (e) {
        console.error(e);
      }
    },
    loadMap() {
      const loader = new Loader({
        apiKey: "AIzaSyD4OaFeki2cLk8pqqgnoNum-iHRe7OzWKE",
        version: "weekly",
        libraries: ["places"],
      })
      const mapContainer = document.getElementById("map")
      loader.load().then((google) => {
        const myLatlng = { lat: this.formData.latitude || -6.918060170323032, lng: this.formData.longitude || 107.61777123901675 };
        const map = new google.maps.Map(mapContainer, {
          center: myLatlng,
          zoom: 18,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
        });
        const input = document.getElementById("map-search");
        const searchBox = new google.maps.places.SearchBox(input);
        map.controls[google.maps.ControlPosition.LEFT_TOP].push(input);
        map.addListener("bounds_changed", () => {
            searchBox.setBounds(map.getBounds());
        });
        let markers = [];

        // Create the initial InfoWindow.
        let infoWindow = new google.maps.InfoWindow({
            content: "Click the map to get Lat/Lng!",
            position: myLatlng,
        });
        infoWindow.open(map);
        searchBox.addListener("places_changed", () => {
          infoWindow.close();
          const places = searchBox.getPlaces();

          if (places.length == 0) {
              return;
          }
          markers.forEach((marker) => {
              marker.setMap(null);
          });
          markers = [];
          const bounds = new google.maps.LatLngBounds();
          places.forEach((place) => {
              if (!place.geometry) {
                  return;
              }
              markers.push(
                  new google.maps.Marker({
                      map,
                      title: place.name,
                      position: place.geometry.location
                  })
              );

              if (place.geometry.viewport) {
                  bounds.union(place.geometry.viewport);
              } else {
                  bounds.extend(place.geometry.location);
              }
              this.formData.latitude = place.geometry.location.toJSON().lat;
              this.formData.longitude = place.geometry.location.toJSON().lng;
          });
          map.fitBounds(bounds);
        });
        map.addListener("click", (mapsMouseEvent) => {
            infoWindow.close();
            markers.forEach((marker) => {
                marker.setMap(null);
            });
            markers = [];
            markers.push(
                new google.maps.Marker({
                    map,
                    title: "Location",
                    position: mapsMouseEvent.latLng
                })
            );
            this.formData.latitude = mapsMouseEvent.latLng.toJSON().lat;
            this.formData.longitude = mapsMouseEvent.latLng.toJSON().lng;
        });
      })
    },
    async submitData() {
      this.$v.$touch()
      if (this.$v.$invalid) toaster.make('Please fill in the form correctly', 'danger')
      else {
        // construct body
        const body = JSON.parse(JSON.stringify(this.formData))
        body.operating_day = Object.keys(body.operating_day).map(el => body.operating_day[el] ? el : null).filter(el => el).join(', ')
        if (!body.operating_day) body.operating_day = "Senin, Selasa, Rabu, Kamis, Jumat"
        // body.operating_start_time = body.operating_start_time ? `${body.operating_start_time}:00` : "08:00:00"
        // body.operating_end_time = body.operating_end_time ? `${body.operating_end_time}:00` : "17:00:00"
        body.operating_start_time = body.operating_start_time ? `${body.operating_start_time}` : "08:00:00"
        body.operating_end_time = body.operating_end_time ? `${body.operating_end_time}` : "17:00:00"
        // submit
        try {
          const res = await this.$api.pickuppoint.savePickupPoint(body, this.pickupPointId)
          if (res.status === 200) {
            toaster.make(res.data.message, 'success')
            this.$router.push('/list-pickup')
          } else toaster.make(res.data.message, 'danger')
        } catch (e) {
          console.error(e);
        }
      }
    },
    clearData(destination) {
      this.$router.replace({ path: '/reload', query: { next: destination } });
    },
  },
  watch: {
    $route(to) {
      const { path } = to
      if (path.indexOf('/add') == 0) this.clearData(path)
    }
  }
}
</script>
<style>
.map-search {
  width: 200px;
  margin-left: .5rem;
}
.map {
  min-height: 240px;
  border-radius: .5rem;
}

</style>
