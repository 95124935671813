<template>
  <div class="mb-5 text-nowrap border-bottom overflow-auto">
    <router-link to="/list-pickup" class="btn btn-wz-tab">List Pick-up Point</router-link>
    <router-link to="/add-pickup" class="btn btn-wz-tab">Add Pick-up Point</router-link>
    <router-link :to="$route.params.id ? `/edit-pickup/${$route.params.id}` : '#'" :disabled="!$route.params.id" class="btn btn-wz-tab">Edit Pick-up Point</router-link>
  </div>
</template>
<script>
export default {
  name: "PickupPointNavTab",
}
</script>
